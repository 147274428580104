@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

#sidebar{
  float: left;
  width: 26.5625%; // Base: 340px
  max-width: 26.5625%; // Base: 340px
  height: 100vh; // Base: 690px
  position: relative;

  h1{
    display: block;
    width: 85.3%;
    height: 34.06%;
    background: url('../../../assets/img/h1.png') no-repeat top left;
    background-size: contain;

    padding: 17.945% 0 0 8.238%;

    font-size: 1.5625vw;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    margin-bottom: 10%;

    a{
      font: inherit;
      line-height: inherit;
      color: inherit;
      text-decoration: none;
      display: inline-block;
      @include anim-hover;
    }

    strong{
      display: block;
      font-size: 3.28125vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1em;
      letter-spacing: normal;
      color: #2591d0;
    }
  }

  div.infos{
    display: block;
    padding: 0 8.2%;

    h2{
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #635f5f;
      display: block;
      margin: 0 0 16px;
      padding: 0;
    }

    p{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #737373;
      display: block;
      margin: 0;
    }
  }

  div.social{
    display: block;
    padding: 0 8.2%;
    position: absolute;
    bottom: 43px;
    left: 0;
    width: 100%;

    .ana{
      @include anim-hover;
      width: 130px;
      display: block;
      text-decoration: none;
      margin: 0 0 21px;

      img{
        display: block;
      }
    }
  }
}

@media only screen and ( min-width: $media-desktop-min ){

}

@media only screen and ( max-width: $media-tablet-max ) and ( min-width: $media-tablet-min ){

}

@media only screen and ( max-width: $media-smartphone-max ){
  @import "./sidebarSmartphone.scss";
}