#listItems{
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  float: none;
  position: relative;
  height: 50.15625vh; // Base: 321px
  min-height: 321px;

  html.mobile-item-open &{
    display: none;
  }

  ul{
    padding: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    list-style: none;

    li{
      padding: 0 24px;
      min-width: 100%;
      width: 100%;
      height: 12.5%;
      min-height: 12.5%;
      margin: 0;
      list-style: none;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;

      &:before{
        content: ' ';
        display: block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
        height: 100%;
        width: 130px;
        right: 0;
        top: 0;
        opacity: 0.2;
      }

      &.ler-o-conjuntura{
        background-image: radial-gradient(circle at 100% 50%, #bbe0ff, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-ler-o-conjuntura-open.png');
        }
      }

      &.navegar-em-mapas{
        background-image: radial-gradient(circle at 100% 50%, #9bcbf5, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-navegar-em-mapas-open.png');
        }
      }

      &.explorar-os-indicadores{
        background-image: radial-gradient(circle at 100% 50%, #7ab4e5, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-explorar-os-indicadores-open.png');
        }
      }

      &.baixar-os-dados{
        background-image: radial-gradient(circle at 100% 50%, #5e9cd1, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-baixar-os-dados-open.png');
        }
      }

      &.consultar-os-sistemas{
        background-image: radial-gradient(circle at 100% 50%, #428ecf, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-consultar-os-sistemas-open.png');
        }
      }

      &.visualizar-em-smartphone{
        background-image: radial-gradient(circle at 100% 50%, #377ebb, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-visualizar-em-smartphone-open.png');
        }
      }

      &.acessar-em-formato-aberto{
        background-image: radial-gradient(circle at 100% 50%, #2a6faa, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-open.png');
        }
      }

      &.consumir-os-geoservicos{
        background-image: radial-gradient(circle at 100% 50%, #145a96, #ffffff 54%);

        &:before{
          background-image: url('../../../assets/img/ico-consumir-os-geoservicos-open.png');
        }
      }

      h3{
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #233c81;
        display: block;
        width: 100%;
        background: svg-url('<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1" id="_1"><path fill="#ffffff" d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>') no-repeat center right;
        background-size: 15px;
        position: relative;
        z-index: 10;

        strong{
          font-weight: bold;
        }
      }
    }
  }
}

div.item.mobo{
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  position: relative;

  background-position: top center;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 220px 0 0;

  &:before{
    content: ' ';
    display: block;
    background-image: url('../../../assets/img/circuitos.png');
    background-size: 93% auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  &:after{
    content: ' ';
    display: block;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    z-index: 10;
    right: 24px;
  }

  &.ler-o-conjuntura{
    background-image: linear-gradient(to bottom, #bbe0ff, #ffffff 30%);
    padding: 240px 0 0;

    &:after{
      background-image: url('../../../assets/img/ico-ler-o-conjuntura-open.png');
      height: 215px;
      top: 58px;
      width: 215px;
    }
  }

  &.navegar-em-mapas{
    background-image: linear-gradient(to bottom, #9bcbf5 -7%, #ffffff 33%);

    &:after{
      background-image: url('../../../assets/img/ico-navegar-em-mapas-open.png');
      width: 237px;
      height: 167px;
      top: 92px;
    }
  }

  &.explorar-os-indicadores{
    background-image: linear-gradient(to bottom, #9bcbf5 -5%, #ffffff 30%);
    padding: 235px 0 0;

    &:after{
      background-image: url('../../../assets/img/ico-explorar-os-indicadores-open.png');
      height: 176px;
      top: 102px;
      width: 167px;
    }
  }

  &.baixar-os-dados{
    background-image: linear-gradient(to bottom, #5e9cd1 -4%, #ffffff 36%, #ffffff 36%);
    padding: 230px 0 0;

    &:after{
      background-image: url('../../../assets/img/ico-baixar-os-dados-open.png');
      height: 255px;
      top: 30px;
      width: 225px;
    }
  }

  &.consultar-os-sistemas{
    background-image: linear-gradient(to bottom, #428ecf -3%, #ffffff 33%);
    padding: 248px 0 0;

    &:after{
      background-image: url('../../../assets/img/ico-consultar-os-sistemas-open.png');
      width: 173px;
      height: 203px;
      top: 60px;
    }
  }

  &.visualizar-em-smartphone{
    background-image: linear-gradient(to bottom, #377ebb -5%, #ffffff 29%);

    &:after{
      background-image: url('../../../assets/img/ico-visualizar-em-smartphone-open.png');
      height: 150px;
      top: 85px;
      width: 235px;
    }
  }

  &.acessar-em-formato-aberto{
    background-image: linear-gradient(to bottom, #2a6faa -7%, #ffffff 37%);

    &:after{
      background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-open.png');
      height: 190px;
      width: 162px;
      top: 85px;
    }
  }

  &.consumir-os-geoservicos{
    background-image: linear-gradient(to bottom, #145a96 -4%, #ffffff 30%);

    &:after{
      background-image: url('../../../assets/img/ico-consumir-os-geoservicos-full-open.png');
      height: 210px;
      width: 210px;
      top: 40px;
    }
  }

  .bt-close{
    background: svg-url('<svg viewBox="0 0 6.82666 6.82666" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path fill="#233c81" d="M5.91083 1.2175c0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669 -0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0l-4.69334 4.69333c-0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669 0.0833031,0.0833031 0.218366,0.0833031 0.301669,0l4.69334 -4.69333z"/><path fill="#233c81" d="M1.2175 0.915827c-0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0 -0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669l4.69334 4.69333c0.0833031,0.0833031 0.218366,0.0833031 0.301669,0 0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669l-4.69334 -4.69333z"/></g></svg>') no-repeat center center;
    background-size: 20px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: solid 0.5px #233c81;
    text-indent: -999em;
    overflow: hidden;
    display: block;
    position: absolute;
    right: 24px;
    top: 60px;
    padding: 0;
    z-index: 20;
  }

  > h3{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    z-index: 19;
    position: absolute;
    left: 0;
    top: 60px;
    display: block;
    width: 100%;
    padding: 0 24px;
    opacity: 0.44;

    strong{
      display: block;
      font-weight: bold;
    }
  }

  > h4{
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #233c81;
    display: block;
    padding: 0 24px;
    margin: 0 0 24px;
    position: relative;
    z-index: 19;

    strong{
      display: block;
      font-weight: bold;
    }
  }

  div.content{
    display: block;
    padding: 0 24px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #737373;

    margin: 0 0 36px;
    position: relative;
    z-index: 19;

    > *{
      font: inherit;
      color: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      display: block;
      margin: 0 0 16px;
    }

    a{
      font: inherit;
      color: #2591d0;
      line-height: inherit;
      letter-spacing: inherit;
      text-decoration: underline;
      
      &:hover{
        text-decoration: none;
      }
    }
  }

  div.links{
    position: relative;
    z-index: 19;
    padding: 0 24px;

    h5{
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #737373;
      display: block;
      padding: 0;
      margin: 0 0 16px;

      a{
        color: #2591d0;
        font: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-decoration: underline;
      }
    }

    ul{
      list-style: none;
      padding: 0;
      display: block;
      width: 100%;

      li{
        list-style: none;
        padding: 0 0 16px;
        margin: 0 0 16px;
        display: block;
        width: 100%;
        border-bottom: 1px solid #dadada;

        &:last-child{
          margin-bottom: 0;
        }

        a{
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #233c81;
          text-decoration: none;
          display: block;
          padding-right: 20px;

          background: svg-url('<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1" id="_1"><path fill="#233c81" d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>') no-repeat center right;
          background-size: 15px;
        }
      }
    }
  }

  div.nav{
    position: relative;
    z-index: 19;
    width: 100%;
    min-width: 100%;
    height: 90px;
    margin-top: 36px;

    p{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #737373;
      display: block;
      margin-bottom: 16px;
    }

    .acessar-em-formato-aberto
    , .visualizar-em-smartphone{
      h6{
        strong{
          display: inline-block !important;
        }
      }
    }

    div.prev{
      float: left;
      width: 50%;
      height: 90px;
      padding: 5px 10px 0 24px;
      border-right: 1px solid #efefef;

      p{
        padding-left: 14px;
      }

      h6{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #737373;
        display: block;
        padding-left: 14px;
        position: relative;

        &:before{
          content: ' ';
          display: block;
          background: svg-url('<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1" id="_1"><path fill="#233c81" d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>') no-repeat center center;
          background-size: 15px;
          background-position: left center;
          background-repeat: no-repeat;
          background-color: transparent;
          position: absolute;
          z-index: 5;
          width: 14px;
          height: 100%;
          top: 2px;
          left: -2px;
          transform: rotate(180deg);
        }

        strong{
          display: block;
          font-weight: bold;
        }
      }
    }

    div.next{
      float: right;
      width: 50%;
      height: 90px;
      padding: 5px 24px 0 10px;
      background-image: radial-gradient(circle at 100% 50%, #428ecf, #ffffff 54%);
      position: relative;

      h6{
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #233c81;
        display: block;
        padding-right: 20px;

        background: svg-url('<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1" id="_1"><path fill="#ffffff" d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>') no-repeat center right;
        background-size: 15px;
        
        strong{
          display: block;
          font-weight: bold;
        }
      }

      &:after{
        content: ' ';
        display: block;
        background-size: contain;
        background-position: right top;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
        z-index: 5;
        right: 10px;
        top: -35px;
        width: 90px;
        height: 80px;
      }

      &.ler-o-conjuntura{
        &:after{
          background-image: url('../../../assets/img/ico-ler-o-conjuntura-open.png');
          right: 10px;
          top: -35px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.navegar-em-mapas{
        &:after{
          background-image: url('../../../assets/img/ico-navegar-em-mapas-open.png');
          right: 10px;
          top: -10px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.explorar-os-indicadores{
        &:after{
          background-image: url('../../../assets/img/ico-explorar-os-indicadores-open.png');
          right: 10px;
          top: -32px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.baixar-os-dados{
        &:after{
          background-image: url('../../../assets/img/ico-baixar-os-dados-open.png');
          right: 10px;
          top: -28px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.consultar-os-sistemas{
        &:after{
          background-image: url('../../../assets/img/ico-consultar-os-sistemas-open.png');
          right: 10px;
          top: -32px;
          width: 70px;
          height: 85px;
        }
      }
    
      &.visualizar-em-smartphone{
        &:after{
          background-image: url('../../../assets/img/ico-visualizar-em-smartphone-open.png');
          right: 10px;
          top: -12px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.acessar-em-formato-aberto{
        &:after{
          background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-open.png');
          right: 10px;
          top: -25px;
          width: 90px;
          height: 80px;
        }
      }
    
      &.consumir-os-geoservicos{
        &:after{
          background-image: url('../../../assets/img/ico-consumir-os-geoservicos-full-open.png');
          right: 10px;
          top: -35px;
          width: 90px;
          height: 80px;
        }
      }
    }
  }
}