#sidebar{
  float: none;
  width: 100%;
  max-width: 100%;
  height: 39.53125vh; // Base: 253px
  min-height: 253px;
  background: url('../../../assets/img/h1.png') no-repeat top left;
  background-size: 230px 193px;
  position: relative;
  z-index: 15;

  html.mobile-item-open &{
    display: none;
  }

  .bt-close
  , .bt-open{
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: solid 0.5px #233c81;
    display: block;
    position: absolute;
    right: 24px;
    top: 86px;
    padding: 0;
    z-index: 15;
    background: none;
  }

  .bt-close{
    background: svg-url('<svg viewBox="0 0 6.82666 6.82666" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path fill="#233c81" d="M5.91083 1.2175c0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669 -0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0l-4.69334 4.69333c-0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669 0.0833031,0.0833031 0.218366,0.0833031 0.301669,0l4.69334 -4.69333z"/><path fill="#233c81" d="M1.2175 0.915827c-0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0 -0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669l4.69334 4.69333c0.0833031,0.0833031 0.218366,0.0833031 0.301669,0 0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669l-4.69334 -4.69333z"/></g></svg>') no-repeat center center;
    background-size: 20px;
    text-indent: -999em;
    overflow: hidden;
  }

  .bt-open{
    color: #233c81;
    text-align: center;
    line-height: 1em;
    font-size: 18px;
  }

  h1{
    font-size: 20px;
    background: none;
    width: auto;
    height: auto;
    padding: 61px 0 0 24px;
    margin-bottom: 0;

    strong{
      font-size: 42px;
    }
  }

  div.infos{
    display: block;
    padding: 0;
    margin-top: 3.8vh;

    h2{
      font-size: 20px;
      margin: 0;
      padding: 0 24px;
    }

    p{
      display: none;
    }
  }

  &.show-about{
    background-color: #f4faff;

    div.infos{
      p{
        background-color: #f4faff;
        padding: 3.75vh 24px 15vh;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #737373;
        margin: 0;
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
      }
    }
  }

  div.social{
    display: none;
  }
}