#portal-do-snirh{
  position: relative;
  width: calc(100vw - (100vw - 100%));
  min-width: calc(100vw - (100vw - 100%));
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;

  .ie &{
    width: 100%;
    min-width: 100%;
  }
}

.clear{
	&:before
	, &:after{
		clear: both;
		content: " ";
		display: table;
	}
}

.center{
  width: $media-desktop-min;
  min-width: $media-desktop-min;
  padding: 0;
  margin: 0 auto;
}

.onlyMobo{
  display: none;
}

.onlyDesk{
  display: inline-block;
}

@media only screen and ( max-width: $media-smartphone-max ){
  #portal-do-snirh{
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: auto;
    overflow: visible;
  }

  .onlyMobo{
    display: inline-block;
  }
  
  .onlyDesk{
    display: none;
  }
}