@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

#listItems.desktop{
  width: 73.4375%;
  max-width: 73.4375%;
  float: left;
  height: 100vh;
  position: relative;
  // display: flex;

  *{
    transition: all $time-trans ease 0s;
  }

  &:hover{
    div.item{
      width: 11.12%;
    }
  }

  &.no-hover{
    div.item{
      width: 4.49%;
      box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
      z-index: unset;

      &:nth-child(8){
        background-image: radial-gradient(circle at 50% 0, #bbe0ff, #ffffff 100%);
      }
  
      &:nth-child(7){
        background-image: radial-gradient(circle at 50% 0, #9bcbf5, #ffffff 100%);
      }
  
      &:nth-child(6){
        background-image: radial-gradient(circle at 50% 0, #7ab4e5, #ffffff 100%);
      }
  
      &:nth-child(5){
        background-image: radial-gradient(circle at 50% 0, #5e9cd1, #ffffff 100%);
      }
  
      &:nth-child(4){
        background-image: radial-gradient(circle at 50% 0, #428ecf, #ffffff 100%);
      }
  
      &:nth-child(3){
        background-image: radial-gradient(circle at 50% 0, #377ebb, #ffffff 100%);
      }
  
      &:nth-child(2){
        background-image: radial-gradient(circle at 50% 0, #2a6faa, #ffffff 100%);
      }
  
      &:nth-child(1){
        background-image: radial-gradient(circle at 50% 0, #145a96, #ffffff 100%);
      }

      div.active
      , div.hover{
        opacity: 0;
        z-index: 5;
      }

      div.init{
        opacity: 1;
        z-index: 10;
      }

      &:hover{
        width: 4.49%;
        box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);
        z-index: unset;

        &:nth-child(8){
          background-image: radial-gradient(circle at 50% 0, #bbe0ff, #ffffff 100%);
        }
    
        &:nth-child(7){
          background-image: radial-gradient(circle at 50% 0, #9bcbf5, #ffffff 100%);
        }
    
        &:nth-child(6){
          background-image: radial-gradient(circle at 50% 0, #7ab4e5, #ffffff 100%);
        }
    
        &:nth-child(5){
          background-image: radial-gradient(circle at 50% 0, #5e9cd1, #ffffff 100%);
        }
    
        &:nth-child(4){
          background-image: radial-gradient(circle at 50% 0, #428ecf, #ffffff 100%);
        }
    
        &:nth-child(3){
          background-image: radial-gradient(circle at 50% 0, #377ebb, #ffffff 100%);
        }
    
        &:nth-child(2){
          background-image: radial-gradient(circle at 50% 0, #2a6faa, #ffffff 100%);
        }
    
        &:nth-child(1){
          background-image: radial-gradient(circle at 50% 0, #145a96, #ffffff 100%);
        }
        
        div.active
        , div.hover{
          opacity: 0;
          z-index: 5;
        }

        div.init{
          opacity: 1;
          z-index: 10;
        }
      }
      

      &.active{
        width: 68.57%;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.18);
        z-index: 15;
        background-position: top center;
        background-color: transparent;
        background-size: cover;
        background-repeat: no-repeat;

        &.ler-o-conjuntura{
          @include bg-ler-o-conjuntura;
        }

        &.navegar-em-mapas{
          @include bg-navegar-em-mapas;
        }

        &.explorar-os-indicadores{
          @include bg-explorar-os-indicadores;
        }

        &.baixar-os-dados{
          @include bg-baixar-os-dados;
        }

        &.consultar-os-sistemas{
          @include bg-consultar-os-sistemas;
        }

        &.visualizar-em-smartphone{
          @include bg-visualizar-em-smartphone;
        }

        &.acessar-em-formato-aberto{
          @include bg-acessar-em-formato-aberto;
        }

        &.consumir-os-geoservicos{
          @include bg-consumir-os-geoservicos;
        }

        div.init
        , div.hover{
          opacity: 0;
          z-index: 5;
        }

        div.init{
          opacity: 1;

          &:before{
            top: 1vh;
            opacity: 0;
            width: 0%;
            height: 0px;
          }

          h3{
            opacity: 0.2;
            left: 4.5%;
          }
        }

        div.active{
          opacity: 1;
          z-index: 10;
        }

        &:hover{
          width: 68.57%;
          box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.18);
          z-index: 15;

          &.ler-o-conjuntura{
            @include bg-ler-o-conjuntura;
          }
  
          &.navegar-em-mapas{
            @include bg-navegar-em-mapas;
          }
  
          &.explorar-os-indicadores{
            @include bg-explorar-os-indicadores;
          }
  
          &.baixar-os-dados{
            @include bg-baixar-os-dados;
          }
  
          &.consultar-os-sistemas{
            @include bg-consultar-os-sistemas;
          }
  
          &.visualizar-em-smartphone{
            @include bg-visualizar-em-smartphone;
          }
  
          &.acessar-em-formato-aberto{
            @include bg-acessar-em-formato-aberto;
          }
  
          &.consumir-os-geoservicos{
            @include bg-consumir-os-geoservicos;
          }

          div.init
          , div.hover{
            opacity: 0;
            z-index: 5;
          }

          div.init{
            opacity: 1;
  
            &:before{
              top: 1vh;
              opacity: 0;
              width: 0%;
              height: 0px;
            }
  
            h3{
              opacity: 0.2;
              left: 4.5%;
            }
          }

          div.active{
            opacity: 1;
            z-index: 10;
          }
        }
      }
    }
  }

  div.item{
    // float: left;
    float: right;
    height: 100vh;
    width: 12.5%;
    position: relative;
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);

    div.init
    , div.hover
    , div.active{
      width: 100%;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:nth-child(8){
      background-image: radial-gradient(circle at 50% 0, #bbe0ff, #ffffff 100%);
    }

    &:nth-child(7){
      background-image: radial-gradient(circle at 50% 0, #9bcbf5, #ffffff 100%);
    }

    &:nth-child(6){
      background-image: radial-gradient(circle at 50% 0, #7ab4e5, #ffffff 100%);
    }

    &:nth-child(5){
      background-image: radial-gradient(circle at 50% 0, #5e9cd1, #ffffff 100%);
    }

    &:nth-child(4){
      background-image: radial-gradient(circle at 50% 0, #428ecf, #ffffff 100%);
    }

    &:nth-child(3){
      background-image: radial-gradient(circle at 50% 0, #377ebb, #ffffff 100%);
    }

    &:nth-child(2){
      background-image: radial-gradient(circle at 50% 0, #2a6faa, #ffffff 100%);
    }

    &:nth-child(1){
      background-image: radial-gradient(circle at 50% 0, #145a96, #ffffff 100%);
    }

    &:hover{
      width: 22.16%;
      box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.18);
      z-index: 15;

      &:nth-child(8){
        background-image: radial-gradient(circle at 50% 0, #bbe0ff, #ffffff 59%);
      }
  
      &:nth-child(7){
        background-image: radial-gradient(circle at 50% 0, #9bcbf5, #ffffff 56%);
      }
  
      &:nth-child(6){
        background-image: radial-gradient(circle at 50% 0, #7ab4e5, #ffffff 56%);
      }
  
      &:nth-child(5){
        background-image: radial-gradient(circle at 50% 0, #5e9cd1, #ffffff 48%);
      }
  
      &:nth-child(4){
        background-image: radial-gradient(circle at 50% 0, #428ecf, #ffffff 48%);
      }
  
      &:nth-child(3){
        background-image: radial-gradient(circle at 50% 0, #377ebb, #ffffff 49%);
      }
  
      &:nth-child(2){
        background-image: linear-gradient(to bottom, #2a6faa -24%, #ffffff 60%);
      }
  
      &:nth-child(1){
        background-image: radial-gradient(circle at 50% 0, #145a96, #ffffff 49%);
      }

      div.init{
        opacity: 0;
        z-index: 5;
      }

      div.hover{
        opacity: 1;
        z-index: 10;
      }
    }

    div.init{
      overflow: hidden;
      opacity: 1;
      z-index: 10;

      &:before{
        content: ' ';
        display: block;
        width: 100%;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        height: 600px;
        opacity: 1;
      }

      h3{
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1em;
        letter-spacing: normal;
        color: #233c81;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 178px;
        width: 300px;
        opacity: 1;

        strong{
          font-weight: bold;
        }
      }
    }

    div.hover{
      display: block;
      opacity: 0;

      &:before{
        content: ' ';
        display: block;
        width: 100%;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
        left: 50%;
        right: 0;
        height: 600px;
        transform: translateX(-50%);
      }

      h3{
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #233c81;
        padding: 0;
        margin: 0 0 24px;
        padding: 0 11.5%;

        strong{
          font-weight: bold;
        }
      }

      div.summary{
        display: block;
        padding: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #737373;
        margin: 0 0 24px;
        padding: 0 11.5%;

        > *{
          font: inherit;
          color: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          display: block;
        }
      }

      > button{
        width: 77%;
        padding: 0;
        position: absolute;
        bottom: 48px;
        left: 11.5%;

        height: 36px;
        border-radius: 4px;
        border: solid 0.5px #233c81;
        background: #fff;
        cursor: pointer;
        @include anim-hover;
        text-align: center;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #233c81;
      }
    }

    div.active{
      overflow: hidden;
      display: block;
      opacity: 0;
      padding: 28.69vh 5.43% 0 11.80%;

      &:before{
        content: ' ';
        display: block;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
      }

      .bt-close{
        background: svg-url('<svg viewBox="0 0 6.82666 6.82666" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path fill="#fff" d="M5.91083 1.2175c0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669 -0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0l-4.69334 4.69333c-0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669 0.0833031,0.0833031 0.218366,0.0833031 0.301669,0l4.69334 -4.69333z"/><path fill="#fff" d="M1.2175 0.915827c-0.0833031,-0.0833031 -0.218366,-0.0833031 -0.301669,0 -0.0833031,0.0833031 -0.0833031,0.218366 0,0.301669l4.69334 4.69333c0.0833031,0.0833031 0.218366,0.0833031 0.301669,0 0.0833031,-0.0833031 0.0833031,-0.218366 0,-0.301669l-4.69334 -4.69333z"/></g></svg>') no-repeat center center;
        background-size: 20px;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: solid 0.5px #fff;
        cursor: pointer;
        @include anim-hover;
        text-indent: -999em;
        overflow: hidden;
        display: block;
        position: absolute;
        left: 16px;
        top: 16px;
        padding: 0;
        z-index: 15;
      }

      h3{
        font-size: 36px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #233c81;
        display: block;
        padding: 0;
        margin: 0 0 24px;
        position: relative;
        z-index: 15;

        strong{
          display: block;
          font-weight: bold;
        }
      }

      div.content{
        display: block;
        padding: 0;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #737373;

        margin: 0 0 20px;
        position: relative;
        z-index: 15;

        > *{
          font: inherit;
          color: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          display: block;
          margin: 0 0 16px;
        }

        a{
          font: inherit;
          color: #2591d0;
          line-height: inherit;
          letter-spacing: inherit;
          text-decoration: underline;
          
          &:hover{
            text-decoration: none;
          }
        }
      }

      div.links{
        position: relative;
        z-index: 15;

        h4{
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #737373;
          display: block;
          padding: 0;
          margin: 0 0 24px;

          a{
            color: #2591d0;
            font: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            text-decoration: underline;

            &:hover{
              text-decoration: none;
            }
          }
        }

        ul{
          list-style: none;
          padding: 0;
          display: block;
          width: 100%;

          li{
            list-style: none;
            padding: 0 0 16px;
            margin: 0 0 16px;
            display: block;
            width: 100%;
            border-bottom: 1px solid #dadada;

            &:last-child{
              border: none;
              padding: 0;
              margin: 0;
            }

            a{
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #233c81;
              text-decoration: none;
              @include anim-hover;
              display: block;

              background: svg-url('<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1" id="_1"><path fill="#233c81" d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>') no-repeat center right;
              background-size: 15px;
            }
          }
        }
      }
    }

    &.ler-o-conjuntura{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-ler-o-conjuntura-init.png');
          //height: 310px;
          top: 4.49vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-ler-o-conjuntura-open.png');
          height: 42.75vh;
          top: 1.44vh;
        }
      }

      div.active{
        padding: 32vh 5.43% 0 11.80%;

        &:before{
          background-image: url('../../../assets/img/ico-ler-o-conjuntura-open.png');
          height: 44vh;
          top: 1.85vh;
          right: 3.4%;
          width: 47%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 140%;
          }
        }
      }
    }

    &.navegar-em-mapas{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-navegar-em-mapas-init.png');
          //height: 241px;
          top: 13.3vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-navegar-em-mapas-open.png');
          height: 34.20vh;
          top: 8.26vh;
        }
      }

      div.active{
        padding: 22vh 5.43% 0 11.80%;

        &:before{
          background-image: url('../../../assets/img/ico-navegar-em-mapas-open.png');
          height: 34vh;
          top: 5vh;
          right: 5.1%;
          width: 60.2%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 155%;
          }
        }
      }
    }

    &.explorar-os-indicadores{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-explorar-os-indicadores-init.png');
          //height: 282px;
          top: 7.5vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-explorar-os-indicadores-open.png');
          height: 41.30vh;
          top: 1.44vh;
        }
      }

      div.active{
        &:before{
          background-image: url('../../../assets/img/ico-explorar-os-indicadores-open.png');
          height: 43.75vh;
          top: 2.2vh;
          right: 3.2%;
          width: 44%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 130%;
          }
        }
      }
    }

    &.baixar-os-dados{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-baixar-os-dados-init.png');
          // height: 351px;
          top: 0;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-baixar-os-dados-open.png');
          height: 43.95vh;
          top: 0.2vh;
        }
      }

      div.active{
        &:before{
          background-image: url('../../../assets/img/ico-baixar-os-dados-open.png');
          height: 47.75vh;
          top: -1.56vh;
          right: 3%;
          width: 46%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 132%;
          }
        }
      }
    }

    &.consultar-os-sistemas{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-consultar-os-sistemas-init.png');
          // height: 342px;
          top: 2.88vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-consultar-os-sistemas-open.png');
          height: 41vh;
          top: 0;
        }
      }

      div.active{
        padding: 35vh 5.43% 0 11.80%;
        
        &:before{
          background-image: url('../../../assets/img/ico-consultar-os-sistemas-open.png');
          height: 46vh;
          top: 0vh;
          right: 6%;
          width: 39%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 110%;
          }
        }
      }
    }

    &.visualizar-em-smartphone{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-visualizar-em-smartphone-init.png');
          // height: 254px;
          top: 10vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-visualizar-em-smartphone-open.png');
          height: 35vh;
          top: 4.6vh;
        }
      }

      div.active{
        &:before{
          background-image: url('../../../assets/img/ico-visualizar-em-smartphone-open.png');
          height: 33vh;
          top: 4.8vh;
          right: 3.5%;
          width: 55%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 178%;
          }
        }
      }
    }

    &.acessar-em-formato-aberto{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-init.png');
          // height: 290px;
          top: 5.7vh;
        }
      }

      div.hover{
        padding-top: 42.02vh;

        &:before{
          background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-open.png');
          height: 43.5vh;
          top: 0;
        }
      }

      div.active{
        &:before{
          background-image: url('../../../assets/img/ico-acessar-em-formato-aberto-open.png');
          height: 46vh;
          top: 0.6vh;
          right: 6%;
          width: 42%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 125%;
          }
        }
      }
    }

    &.consumir-os-geoservicos{
      div.init{
        &:before{
          background-image: url('../../../assets/img/ico-consumir-os-geoservicos-init.png');
          // height: 327px;
          top: 0;
        }
      }

      div.hover{
        padding-top: 46.5vh;

        &:before{
          background-image: url('../../../assets/img/ico-consumir-os-geoservicos-open.png');
          background-position: top right;
          height: 44.5vh;
          top: 0;
          left: auto;
          transform: none;
          right: 0;
        }
      }

      div.active{
        &:before{
          background-image: url('../../../assets/img/ico-consumir-os-geoservicos-full-open.png');
          height: 42vh;
          top: 0vh;
          right: 3.4%;
          width: 50%;
        }
      }

      &:hover{
        div.hover{
          &:before{
            width: 127%;
          }
        }
      }
    }
  }
}

@media only screen and ( min-width: $media-desktop-min ){

}

@media only screen and ( max-width: $media-tablet-max ) and ( min-width: $media-tablet-min ){

}

@media only screen and ( max-width: $media-smartphone-max ){
  @import "./listItemsSmartphone.scss";
}