@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

footer{
  
}

@media only screen and ( min-width: $media-desktop-min ){

}

@media only screen and ( max-width: $media-tablet-max ) and ( min-width: $media-tablet-min ){

}

@media only screen and ( max-width: $media-smartphone-max ){
  @import "./footerSmartphone.scss";
}