footer{
  width: 100%;
  min-width: 100%;
  height: 10.3125vh; // Base: 66px
  min-height: 66px;
  padding: 0 24px;
  position: relative;

  html.mobile-item-open &{
    display: none;
  }

  .ana{
    width: 95px;
    position: absolute;
    left: 24px;
    bottom: 15px;
  }

  .socialMedia{
    position: absolute;
    right: 24px;
    bottom: 21px;

    li{
      margin: 0 0 0 12px;
    }
  }
}