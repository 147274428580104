@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";
ul.socialMedia {
    display: block;
    text-align: left;
    list-style: none;
    padding: 0;
    height: 16px;
    li {
        display: inline-block;
        padding: 0;
        list-style: none;
        margin: 0 12px 0 0;
        float: left;
        a {
            overflow: hidden;
            text-indent: -999em;
            display: inline-block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            text-decoration: none;
            transition: all $time-trans ease 0s;
            width: 16px;
            height: 16px;
            margin: 4px 0 0;
            // Foi feita a inserção dos arquivos SVG (de ícones) no CSS. Dessa forma, pudemos fazer a transição de cores no hover de forma suave.
            &.yt {
                background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"><path style="fill:#82b4df;" d="M506.703,145.655c0,0-5.297-37.959-20.303-54.731c-19.421-22.069-41.49-22.069-51.2-22.952 C363.697,62.676,256,61.793,256,61.793l0,0c0,0-107.697,0.883-179.2,6.179c-9.71,0.883-31.779,1.766-51.2,22.952 C9.71,107.697,5.297,145.655,5.297,145.655S0,190.676,0,235.697v41.49c0,45.021,5.297,89.159,5.297,89.159 s5.297,37.959,20.303,54.731c19.421,22.069,45.021,21.186,56.497,23.835C122.703,449.324,256,450.207,256,450.207 s107.697,0,179.2-6.179c9.71-0.883,31.779-1.766,51.2-22.952c15.007-16.772,20.303-54.731,20.303-54.731S512,321.324,512,277.186 v-41.49C512,190.676,506.703,145.655,506.703,145.655"/><polygon style="fill:#fff;" points="194.207,166.841 194.207,358.4 361.931,264.828 "/></svg>');
                width: 23px;
                height: 23px;
                margin: 0;
            }
            &.ig {
                background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"><g><g><path style="fill:#82b4df;" d="M363.273,0H148.728C66.719,0,0,66.719,0,148.728v214.544C0,445.281,66.719,512,148.728,512h214.544 C445.281,512,512,445.281,512,363.273V148.728C512,66.719,445.281,0,363.273,0z M472,363.272C472,423.225,423.225,472,363.273,472 H148.728C88.775,472,40,423.225,40,363.273V148.728C40,88.775,88.775,40,148.728,40h214.544C423.225,40,472,88.775,472,148.728 V363.272z"/></g></g><g><g><path style="fill:#82b4df;" d="M256,118c-76.094,0-138,61.906-138,138s61.906,138,138,138s138-61.906,138-138S332.094,118,256,118z M256,354 c-54.037,0-98-43.963-98-98s43.963-98,98-98s98,43.963,98,98S310.037,354,256,354z"/></g></g><g><g><circle style="fill:#82b4df;" cx="396" cy="116" r="20"/></g></g></svg>');
            }
            &.tw {
                background-image: svg-url('<svg version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><g id="_x37__stroke"><g id="Twitter"><rect clip-rule="evenodd" fill="none" fill-rule="evenodd"/><path clip-rule="evenodd" d="M128,23.294 c-4.703,2.142-9.767,3.59-15.079,4.237c5.424-3.328,9.587-8.606,11.548-14.892c-5.079,3.082-10.691,5.324-16.687,6.526 c-4.778-5.231-11.608-8.498-19.166-8.498c-14.493,0-26.251,12.057-26.251,26.927c0,2.111,0.225,4.16,0.676,6.133 C41.217,42.601,21.871,31.892,8.91,15.582c-2.261,3.991-3.554,8.621-3.554,13.552c0,9.338,4.636,17.581,11.683,22.412 c-4.297-0.131-8.355-1.356-11.901-3.359v0.331c0,13.051,9.053,23.937,21.074,26.403c-2.201,0.632-4.523,0.948-6.92,0.948 c-1.69,0-3.343-0.162-4.944-0.478c3.343,10.694,13.035,18.483,24.53,18.691c-8.986,7.227-20.315,11.533-32.614,11.533 c-2.119,0-4.215-0.123-6.266-0.37c11.623,7.627,25.432,12.088,40.255,12.088c48.309,0,74.717-41.026,74.717-76.612 c0-1.171-0.023-2.342-0.068-3.49C120.036,33.433,124.491,28.695,128,23.294" fill="#82b4df" fill-rule="evenodd" id="Twitter_1_"/></g></g></svg>');
                width: 20px;
                height: 20px;
                margin-top: 2px;
            }
            &.fb {
                background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 155.139 155.139" style="enable-background:new 0 0 155.139 155.139;"><g><path id="f_1_" style="fill:#82b4df;" d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z"/></g></svg>');
            }
            &.in {
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16"><path fill="#82b4df" fill-rule="evenodd" d="M18 16h-3.978v-5.625c0-1.472-.623-2.477-1.991-2.477-1.047 0-1.629.678-1.9 1.332-.101.235-.085.561-.085.888V16H6.104s.051-9.964 0-10.87h3.942v1.707c.232-.746 1.492-1.81 3.502-1.81C16.04 5.026 18 6.59 18 9.956V16zM2.119 3.771h-.026C.823 3.771 0 2.941 0 1.887 0 .813.848 0 2.143 0c1.294 0 2.09.811 2.116 1.884 0 1.053-.822 1.887-2.14 1.887zM.454 5.131h3.508V16H.454V5.13z"/></svg>');
                width: 18px;
                height: 17px;
                margin-top: 3px;
            }
            &.wp {
                background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 90 90" style="enable-background:new 0 0 90 90;"><g><path id="WhatsApp" style="fill:#82b4df;" d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522 c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982 c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537 c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938 c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537 c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333 c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882 c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977 c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344 c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223 C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"/></g></svg>');
            }
            &.email {
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 20 14" style="enable-background:new 0 0 20 14;" xml:space="preserve"><style type="text/css">.st0{fill:#82B4DF;}</style>
 <path class="st0" d="M18.8,0H1.2C0.9,0,0.6,0.1,0.4,0.3C0.1,0.6,0,0.9,0,1.2v11.7c0,0.3,0.1,0.6,0.4,0.8C0.6,13.9,0.9,14,1.2,14  h17.5c0.3,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.4-0.8V1.2c0-0.3-0.1-0.6-0.4-0.8C19.4,0.1,19.1,0,18.8,0z M17.8,12.8H2.3l4.4-4.2  L5.8,7.8l-4.5,4.4V2.1L9,9.3c0.2,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3l8-7.4v10.2l-4.6-4.3l-0.9,0.8L17.8,12.8z M2.1,1.2h15.7  L9.9,8.4L2.1,1.2z"/></svg>');
 width: 20px;
                height: 20px;
                margin-top: 2px;
            }
            // Foi feita a inserção dos arquivos SVG (de ícones) no CSS. Dessa forma, pudemos fazer a transição de cores no hover de forma suave.
            &:hover {
                &.yt {
                    background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"><path style="fill:#635f5f;" d="M506.703,145.655c0,0-5.297-37.959-20.303-54.731c-19.421-22.069-41.49-22.069-51.2-22.952 C363.697,62.676,256,61.793,256,61.793l0,0c0,0-107.697,0.883-179.2,6.179c-9.71,0.883-31.779,1.766-51.2,22.952 C9.71,107.697,5.297,145.655,5.297,145.655S0,190.676,0,235.697v41.49c0,45.021,5.297,89.159,5.297,89.159 s5.297,37.959,20.303,54.731c19.421,22.069,45.021,21.186,56.497,23.835C122.703,449.324,256,450.207,256,450.207 s107.697,0,179.2-6.179c9.71-0.883,31.779-1.766,51.2-22.952c15.007-16.772,20.303-54.731,20.303-54.731S512,321.324,512,277.186 v-41.49C512,190.676,506.703,145.655,506.703,145.655"/><polygon style="fill:#fff;" points="194.207,166.841 194.207,358.4 361.931,264.828 "/></svg>');
                }
                &.ig {
                    background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"><g><g><path style="fill:#635f5f;" d="M363.273,0H148.728C66.719,0,0,66.719,0,148.728v214.544C0,445.281,66.719,512,148.728,512h214.544 C445.281,512,512,445.281,512,363.273V148.728C512,66.719,445.281,0,363.273,0z M472,363.272C472,423.225,423.225,472,363.273,472 H148.728C88.775,472,40,423.225,40,363.273V148.728C40,88.775,88.775,40,148.728,40h214.544C423.225,40,472,88.775,472,148.728 V363.272z"/></g></g><g><g><path style="fill:#635f5f;" d="M256,118c-76.094,0-138,61.906-138,138s61.906,138,138,138s138-61.906,138-138S332.094,118,256,118z M256,354 c-54.037,0-98-43.963-98-98s43.963-98,98-98s98,43.963,98,98S310.037,354,256,354z"/></g></g><g><g><circle style="fill:#635f5f;" cx="396" cy="116" r="20"/></g></g></svg>');
                }
                &.tw {
                    background-image: svg-url('<svg version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><g id="_x37__stroke"><g id="Twitter"><rect clip-rule="evenodd" fill="none" fill-rule="evenodd"/><path clip-rule="evenodd" d="M128,23.294 c-4.703,2.142-9.767,3.59-15.079,4.237c5.424-3.328,9.587-8.606,11.548-14.892c-5.079,3.082-10.691,5.324-16.687,6.526 c-4.778-5.231-11.608-8.498-19.166-8.498c-14.493,0-26.251,12.057-26.251,26.927c0,2.111,0.225,4.16,0.676,6.133 C41.217,42.601,21.871,31.892,8.91,15.582c-2.261,3.991-3.554,8.621-3.554,13.552c0,9.338,4.636,17.581,11.683,22.412 c-4.297-0.131-8.355-1.356-11.901-3.359v0.331c0,13.051,9.053,23.937,21.074,26.403c-2.201,0.632-4.523,0.948-6.92,0.948 c-1.69,0-3.343-0.162-4.944-0.478c3.343,10.694,13.035,18.483,24.53,18.691c-8.986,7.227-20.315,11.533-32.614,11.533 c-2.119,0-4.215-0.123-6.266-0.37c11.623,7.627,25.432,12.088,40.255,12.088c48.309,0,74.717-41.026,74.717-76.612 c0-1.171-0.023-2.342-0.068-3.49C120.036,33.433,124.491,28.695,128,23.294" fill="#635f5f" fill-rule="evenodd" id="Twitter_1_"/></g></g></svg>');
                }
                &.fb {
                    background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 155.139 155.139" style="enable-background:new 0 0 155.139 155.139;"><g><path id="f_1_" style="fill:#635f5f;" d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z"/></g></svg>');
                }
                &.in {
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16"><path fill="#635f5f" fill-rule="evenodd" d="M18 16h-3.978v-5.625c0-1.472-.623-2.477-1.991-2.477-1.047 0-1.629.678-1.9 1.332-.101.235-.085.561-.085.888V16H6.104s.051-9.964 0-10.87h3.942v1.707c.232-.746 1.492-1.81 3.502-1.81C16.04 5.026 18 6.59 18 9.956V16zM2.119 3.771h-.026C.823 3.771 0 2.941 0 1.887 0 .813.848 0 2.143 0c1.294 0 2.09.811 2.116 1.884 0 1.053-.822 1.887-2.14 1.887zM.454 5.131h3.508V16H.454V5.13z"/></svg>');
                }
                &.wp {
                    background-image: svg-url('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 90 90" style="enable-background:new 0 0 90 90;"><g><path id="WhatsApp" style="fill:#635f5f;" d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522 c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982 c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537 c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938 c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537 c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333 c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882 c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977 c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344 c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223 C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"/></g></svg>');
                }
                &.email {
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 20 14" style="enable-background:new 0 0 20 14;" xml:space="preserve">
 <style type="text/css">.st0 {
                        fill: #635F5F;
                    }
                    </style><path class="st0" d="M18.8,0H1.2C0.9,0,0.6,0.1,0.4,0.3C0.1,0.6,0,0.9,0,1.2v11.7c0,0.3,0.1,0.6,0.4,0.8C0.6,13.9,0.9,14,1.2,14  h17.5c0.3,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.4-0.8V1.2c0-0.3-0.1-0.6-0.4-0.8C19.4,0.1,19.1,0,18.8,0z M17.8,12.8H2.3l4.4-4.2  L5.8,7.8l-4.5,4.4V2.1L9,9.3c0.2,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3l8-7.4v10.2l-4.6-4.3l-0.9,0.8L17.8,12.8z M2.1,1.2h15.7  L9.9,8.4L2.1,1.2z"/></svg>');

                }
            }
        }
    }
}

@media only screen and ( min-width: $media-desktop-min) {}

@media only screen and ( max-width: $media-tablet-max) and ( min-width: $media-tablet-min) {}

@media only screen and ( max-width: $media-smartphone-max) {
    @import "./socialMediaSmartphone.scss";
}